import React from 'react';
import logo from './logo.svg';
import './App.css';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor:"#000",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function App() {
  
  const classes = useStyles();

  return (
    <div className="App">
      {/* <div className={classes.root}>
        <AppBar position="fixed" color="default">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>Done-Well</Typography>
            <Button color="inherit">Login</Button>
          </Toolbar>
        </AppBar>
      </div> */}
      <header className="App-header">
        <h1>Done Well<span>&#11044;</span></h1>
        <p className="quote">&ldquo;done with great care and skill to ensure mistakes are not made.&rdquo;</p>
        <p>well-done. :) You found us <span className="love">♥</span></p>
      </header>
    </div>
  );
}

export default App;
